const leftNavChefAdmin = [
  {
    order: 1,
    name: 'Home',
    route: '/ChefAdminHome',
    expanded: false,
    icon: <i className="mdi mdi-pin"></i>
  },
];

export default leftNavChefAdmin;
