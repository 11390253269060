const supportTeamNavChefAdmin = [
  {
    order: 1,
    name: "Home",
    route: "/supportTeam",
    expanded: false,
    icon: <i className="bx bx-grid-alt"></i>,
  },
  {
    order: 2,
    name: "B2B Kids Upload",
    route: "/supportTeam/b2b",
    expanded: false,
    icon: <i className="bx bx-grid-alt"></i>,
  },
  {
    order: 3,
    name: "Kid List",
    route: "/supportTeam/kidList",
    expanded: false,
    icon: <i className="bx bx-grid-alt"></i>,
  },
  {
    order: 4,
    name: "Subscriptions",
    route: "/supportTeam/subscription",
    expanded: false,
    icon: <i className="bx bx-grid-alt"></i>,
  },
  {
    order: 5,
    name: "Mark Order Dispatched",
    route: "/supportTeam/markOrderDispatched",
    expanded: false,
    icon: <i className="bx bx-grid-alt"></i>,
  },
  {
    order: 6,
    name: "Mark Order Delivered",
    route: "/supportTeam/markOrderDeliverd",
    expanded: false,
    icon: <i className="bx bx-grid-alt"></i>,
  },
  {
    order: 7,
    name: "Issue Tracking",
    route: "/supportTeam/issue-tracking",
    expanded: false,
    icon: <i className="bx bx-grid-alt"></i>,
  },
  {
    order: 8,
    name: "Issue Tracking Analytics",
    route: "/supportTeam/issueTrackingAnalytics",
    expanded: false,
    icon: <i className="bx bx-grid-alt"></i>,
  },
  {
    order: 9,
    name: "Transaction Logs",
    route: "/supportTeam/transaction-logs",
    expanded: false,
    icon: <i className="bx bx-grid-alt"></i>,
  },
  {
    order: 9,
    name: "School wise-Extend Subscription",
    route: "/supportTeam/extend-subscription",
    expanded: false,
    icon: <i className="bx bx-grid-alt"></i>,
  },
  {
    order: 9,
    name: "Extend Subscription",
    route: "/supportTeam/extend-subscription-wise",
    expanded: false,
    icon: <i className="bx bx-grid-alt"></i>,
  },
  {
    order: 9,
    name: "Kids Birthdays",
    route: "/supportTeam/kidsBirthdays",
    expand: false,
    icon: <i className="bx bx-grid-alt"></i>,
  },
];

export default supportTeamNavChefAdmin;
