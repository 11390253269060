const leftNavLogisticsTeam = [
  {
    order: 1,
    name: "Home",
    route: "/logisticTeamHome",
    expanded: false,
    icon: <i className="mdi mdi-pin"></i>,
  },
  {
    order: 2,
    name: "Route Wise Orders",
    route: "/logisticTeamHome/routeWiseOrders",
    expand: false,
  },
  {
    order: 3,
    name: "Permanent Route",
    route: "/logisticTeamHome/permanentRoute",
    expand: false,
  },
  {
    order: 4,
    name: "Missing Box Report",
    route: "/logisticTeamHome/missingBoxReport",
    expand: false,
  },
  {
    order: 5,
    name: "Notifications",
    route: "/logisticTeamHome/notifications",
    expand: false,
  },
  {
    order: 6,
    name: "Van Tracking",
    route: "/logisticTeamHome/vanTracking",
    expand: false,
  },
  {
    order: 7,
    name: "Order Tracking",
    route: "/logisticTeamHome/orderTracking",
    expand: false,
  },
];

export default leftNavLogisticsTeam;
