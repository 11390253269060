import "../public/scss/theme.scss";
import "../global-styles.css";
import "../tailwind.css";
import "../map.css";
import "../markerCluster.css";
import { appWithTranslation } from "next-i18next";
import nextI18NextConfig from "../next-i18next.config.js";
import { Provider } from "react-redux";
import { store } from "../app/store";
import { getLayout } from "components/Layout/LayoutConfiguration";
import WithAuth from "@/components/Auth/WithAuth";

function MyApp({ Component, pageProps, router }) {
  let layout = getLayout(router.pathname);

  return (
    <Provider store={store}>
      <WithAuth router={router}>
        {layout({ children: <Component {...pageProps} /> })}
      </WithAuth>
    </Provider>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);
