import leftNavNutritionist from "./LeftNavData/leftNavNutritionist";
import leftNavSystemAdmin from "./LeftNavData/leftNavSystemAdmin";
import leftNavPODAdmin from "./LeftNavData/leftNavPODAdmin";
import leftNavKitchenAdmin from "./LeftNavData/leftNavKitchenAdmin";
import leftNavLogiticsAdmin from "./LeftNavData/leftNavLogisticsTeam";
import leftNavPackagingTeamAdmin from "./LeftNavData/leftNavPackagingTeam";
import leftNavChefAdmin from "./LeftNavData/leftNavChefAdmin";
import leftNavSupportTeam from "./LeftNavData/supportTeamNavChefAdmin";
import leftNavFinanceAdmin from "./LeftNavData/leftNavFinanceAdmin";
const getNavigationData = (userRoles) => {
  const roles = {
    "System Admin": leftNavSystemAdmin,
    Nutritionist: leftNavNutritionist,
    "POD Admin": leftNavPODAdmin,
    "Kitchen Admin": leftNavKitchenAdmin,
    "Logistics Team": leftNavLogiticsAdmin,
    "Chef Team": leftNavChefAdmin,
    "Support Team": leftNavSupportTeam,
    "Packaging Team": leftNavPackagingTeamAdmin,
    "Finance Admin": leftNavFinanceAdmin,
  };
  let navData = [];
  for (let role of userRoles) {
    if (role in roles) {
      for (let item of roles[role]) {
        navData.push(item);
      }
    }
  }

  navData = {
    "": navData,
  };
  return navData;
};

export default getNavigationData;
