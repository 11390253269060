import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useEffect, useState } from "react";

const DropDown = ({ options, onClick, defaultDropDownLabel }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(defaultDropDownLabel);

  useEffect(() => {
    setDropdownValue(defaultDropDownLabel);
  }, [options.length]);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <>
      <div className="">
        <Dropdown size="lg" isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            caret
            // className="tw-text-xs sm:tw-text-[16px]"
            style={{
              width: "10%",
              minWidth: "fit-content",
              margin: "0 0",
            }}
          >
            {dropdownValue} {!dropdownOpen ? <>&#9660;</> : <>&#9650;</>}
          </DropdownToggle>

          <DropdownMenu>
            {options.map((item) => (
              <DropdownItem
                key={item.label}
                id={item.value}
                onClick={(e) => {
                  setDropdownValue(e.currentTarget.textContent);
                  onClick(e.target.getAttribute("id"));
                }}
              >
                {item.label?.toUpperCase()}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    </>
  );
};

export default DropDown;
