const leftNavNutritionist = [
  {
    order: 1,
    name: 'Pods',
    route: '/nutritions/pods',
    expanded: false,
    icon: <i className="mdi mdi-pin"></i>
  },
  {
    order: 2,
    name: 'Nutritions',
    route: '/nutritions',
    expanded: false,
    icon: <i className="mdi mdi-bookshelf"></i>
  }
];

export default leftNavNutritionist;
