import React from 'react';
import PropTypes from 'prop-types';

const EmptyLayout = ({ children }) => {
  return <>{children}</>;
};

EmptyLayout.propTypes = {
  children: PropTypes.node,
};

export default EmptyLayout;
