import React, { useState, useEffect } from "react";
import { useRouter, withRouter } from "next/router";
import { removeAuth, getAuth } from "services/identity.service";
import SimpleBar from "simplebar-react";

import Link from "next/link";
import getNavigationData from "./getNavigationData";
import DropDown from "@/components/UI/DropDown";

const roles = [
  { value: "System Admin", label: "System Admin" },
  { value: "Nutritionist", label: "Nutritionist" },
  { value: "POD Admin", label: "POD Admin" },
  { value: "Kitchen Admin", label: "Kitchen Admin" },
  { value: "Logistics Team", label: "Logistics Team" },
  { value: "Chef Team", label: "Chef Team" },
  { value: "Support Team", label: "Support Team" },
  { value: "Packaging Team", label: "Packaging Team" },
  { value: "Finance Admin", label: "Finance Admin" },
];

// const roles = [
//   {
//     name: "System Roles",
//     expanded: true,
//     children: [
//       {
//         order: 1,
//         name: "System Admin",
//         route: "/menus",
//         icon: <i className="bx bx-briefcase"></i>,
//       },
//       {
//         order: 2,
//         name: "Nutritionist",
//         route: "/nutritions/pods",
//         icon: <i className="bx bx-briefcase"></i>,
//       },
//       {
//         order: 3,
//         name: "POD Admin",
//         route: "/podAdminHome",
//         icon: <i className="bx bx-briefcase"></i>,
//       },
//       {
//         order: 4,
//         name: "Kitchen Admin",
//         route: "/KitchenAdminHome",
//         icon: <i className="bx bx-briefcase"></i>,
//       },
//       {
//         order: 5,
//         name: "Logistics Team",
//         route: "/logisticTeamHome",
//         icon: <i className="bx bx-briefcase"></i>,
//       },
//       {
//         order: 6,
//         name: "Chef Team",
//         route: "/ChefAdminHome",
//         icon: <i className="bx bx-briefcase"></i>,
//       },
//       {
//         order: 7,
//         name: "Support Team",
//         route: "/supportTeam",
//         icon: <i className="bx bx-briefcase"></i>,
//       },
//       {
//         order: 8,
//         name: "Packaging Team",
//         route: "/packagingTeamHome",
//         icon: <i className="bx bx-briefcase"></i>,
//       },
//       {
//         order: 9,
//         name: "Finance Admin",
//         route: "/financeHome",
//         icon: <i className="bx bx-briefcase"></i>,
//       },
//     ],
//   },
// ];

const SidebarContent = (props) => {
  const showNestedChildren = (child, expanded, itemName) => {
    if (!child) return "";
    if (expanded) {
      if (itemExpaned && showItem == itemName) {
        return "has-arrow-up";
      } else {
        return "has-arrow";
      }
    }
    return "mm-show has-arrow";
  };

  // const showSystemAdminRoles = (child, expanded, itemName) => {
  //   if (!child) return "";
  //   if (expanded) {
  //     if (rolesItemExpaned && showItemRoles == itemName) {
  //       return "has-arrow-up";
  //     } else {
  //       return "has-arrow";
  //     }
  //   }
  //   return "mm-show has-arrow";
  // };

  const auth = getAuth();
  const superAdminrole = auth?.roles?.includes("MB Super Admin");
  const [leftNavigation, setleftNavigation] = useState([]);
  const [systemRoles, setSystemRoles] = useState(
    superAdminrole ? ["System Admin"] : auth?.roles
  );
  const [showChildren, setShowChildren] = useState(false);
  const [itemExpaned, setItemExpaned] = useState(false);
  const [rolesItemExpaned, setRolesItemExpaned] = useState(false);
  const [showItem, setShowItem] = useState("");
  const [showItemRoles, setShowItemRoles] = useState("");

  const router = useRouter();

  useEffect(() => {
    setleftNavigation(getNavigationData(systemRoles));
  }, [systemRoles]);

  let getRoute = (role) => {
    let route = "";
    switch (role) {
      case "System Admin":
        route = "/menus";
        break;
      case "Nutritionist":
        route = "/nutritions/pods";
        break;
      case "POD Admin":
        route = "/podAdminHome";
        break;
      case "Chef Team":
        route = "/ChefAdminHome";
        break;
      case "Kitchen Admin":
        route = "/KitchenAdminHome";
        break;
      case "Logistics Team":
        route = "/logisticTeamHome";
        break;
      case "Support Team":
        route = "/supportTeam";
        break;
      case "Packaging Team":
        route = "/packagingTeamHome";
        break;
      case "Finance Admin":
        route = "/financeHome";
        break;
      default:
        route = "/menus";
        break;
    }
    return route;
  };

  let handleRouting = (value) => {
    let role = [value];
    setSystemRoles(role);
    let route = getRoute(value);
    router.push(route);
  };

  return (
    <React.Fragment>
      <SimpleBar className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {auth?.roles?.includes("MB Super Admin") && (
              <div className="tw-flex tw-justify-center tw-items-center tw-my-5">
                <DropDown
                  onClick={(value) => handleRouting(value)}
                  options={roles}
                  defaultDropDownLabel={roles[0].label}
                ></DropDown>
              </div>
              // <div>
              //   {roles.map((item, index2) => {
              //     return (
              //       <li key={index2}>
              //         <Link href={item.route || props.router.asPath}>
              //           <a
              //             className={showSystemAdminRoles(
              //               item.children,
              //               item.expanded,
              //               item.name
              //             )}
              //             onClick={() => {
              //               setRolesItemExpaned((prev) => {
              //                 return showItemRoles != item.name ? true : !prev;
              //               });
              //               setShowItemRoles(item.name);
              //             }}
              //           >
              //             {item.icon}
              //             <span>{item.name}</span>
              //           </a>
              //         </Link>
              //         {item.children && item.expanded && (
              //           <ul
              //             className={`sub-menu ${
              //               rolesItemExpaned && showItemRoles == item.name
              //                 ? "mm-show"
              //                 : "mm-collapse"
              //             }`}
              //             aria-expanded="false"
              //           >
              //             {item.children.map((submenu, index3) => {
              //               return (
              //                 <li key={index3}>
              //                   <div
              //                     onClick={() =>
              //                       handleRouting(submenu.route, submenu.name)
              //                     }
              //                     href={submenu.route}
              //                   >
              //                     <a
              //                       className={
              //                         props.router.asPath === submenu.route
              //                           ? "mm-active"
              //                           : ""
              //                       }
              //                     >
              //                       {submenu.name}
              //                     </a>
              //                   </div>
              //                 </li>
              //               );
              //             })}
              //           </ul>
              //         )}
              //       </li>
              //     );
              //   })}
              // </div>
            )}
            {Object.keys(leftNavigation).map((key, index) => {
              return (
                <div key={index}>
                  <li className="menu-title">{key}</li>
                  {leftNavigation[key].map((item, index2) => {
                    return (
                      <li key={index2}>
                        <Link href={item.route || props.router.asPath}>
                          <a
                            className={showNestedChildren(
                              item.children,
                              item.expanded,
                              item.name
                            )}
                            onClick={() => {
                              setItemExpaned((prev) => {
                                return showItem != item.name ? true : !prev;
                              });
                              // item.expanded = !item.expanded;
                              setleftNavigation(leftNavigation);
                              setShowChildren(true);
                              setShowItem(item.name);
                            }}
                          >
                            {item.icon}
                            <span>{item.name}</span>
                          </a>
                        </Link>
                        {item.children && item.expanded && (
                          <ul
                            // className="sub-menu"
                            className={`sub-menu ${
                              itemExpaned && showItem == item.name
                                ? "mm-show"
                                : "mm-collapse"
                            }`}
                            aria-expanded="false"
                          >
                            {item.children.map((submenu, index3) => {
                              return (
                                <li key={index3}>
                                  <Link href={submenu.route}>
                                    <a
                                      className={
                                        props.router.asPath === submenu.route
                                          ? "mm-active"
                                          : ""
                                      }
                                    >
                                      {submenu.name}
                                    </a>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </div>
              );
            })}
            <li className="">
              <Link href={"/#"}>
                <a
                  onClick={() => {
                    removeAuth();
                    // router.reload();
                  }}
                >
                  <i className="bx bx-log-out-circle"></i>
                  <span>LOGOUT!</span>
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default withRouter(SidebarContent);
