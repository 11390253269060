const leftNavPODAdmin = [
  {
    order: 1,
    name: "Home",
    route: "/podAdminHome",
    expanded: false,
  },
  {
    order: 2,
    name: "Team Details",
    route: "/team",
    expanded: false,
  },
  {
    order: 3,
    name: "Packaging Details",
    route: "/podAdminHome/packagingDetails",
    expanded: false,
  },
  {
    order: 5,
    name: "Delivery Details",
    route: "/podAdminHome/deliveryDetails",
    expanded: false,
  },
];

export default leftNavPODAdmin;
